
import React, { useEffect, useState, useRef } from 'react'
import { CustomEventType, PromptOptions, UpgradeComponentData } from 'app/types'
import { useAppDispatch, useAppSelector, useSessionStorage } from 'app/hooks'
import { Spinner, IconButton, TourIFrame, Prompt, Icon } from 'components'
import * as fnc from 'helpers/fnc'
import { toggleFullscreen } from 'actions/appActions'
import { getTourUrl } from 'helpers/config'
import { logger } from 'helpers/logger'
import { getOptionComponent, getOptionProducts, getTourLink } from './upgrades'
import { pbrBlockGeometryInfo } from '@babylonjs/core/Shaders/ShadersInclude/pbrBlockGeometryInfo'

function orderSort(a, b) {
    return a.order - b.order
}

interface UpgradeTourProps {
    app: AppData,
    maps: Dict,
    media: MediaData,
    group: UpgradeOptionGroupData,
    selections: Dict,
    editPackage: Dict,
    editOption: UpgradeOptionData,
    editComponent: UpgradeComponentData,
    focusOption: boolean,
    onSelect: () => void,
    onProduct: () => void,
    onPackage: () => void,
    viewRef: HTMLDivElement,
    focus: boolean,
}

function getLink(x) {
    if (!x) {
        return null
    }
    const tokens = x.split('_')
    if (tokens.length > 1) {
        return tokens[1]
    }
    return tokens[0]
}

export function UpgradeTour(props: UpgradeTourProps) {
    const {
        app,
        media,
        group,
        selections,
        editPackage,
        editOption,
        editComponent,
        focusOption,
        onSelect,
        onProduct,
        onPackage,
        maps,
        viewRef,
        focus,
    } = props

    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(0)
    const [url, setUrl] = useState(null)
    const [productMap, setProductMap] = useState({})
    const screen = useAppSelector((state: RootState) => state.app.screen)
    const fullscreen = useAppSelector((state: RootState) => state.app.fullscreen)
    const [showInlineDisclaimer, setShowInlineDisclaimer] = useState(false)
    const [showHandAnimation, setShowHandAnimation] = useState(false)

    const hideSet = useRef(new Set())

    // const isFullscreen = fullscreen || (viewRef != null && viewRef.current != null && (fnc.isFullscreen() == viewRef || viewRef.current.classList.includes('fullscreen')))
    const isFullscreen = fullscreen || (viewRef != null && viewRef.current != null && fnc.isFullscreen() == viewRef)

    useEffect(() => {
        // See if disclaimer has been shown
        /*const key = `tour-disclaimer`//-${media.id}`
        const alreadyShown = sessionStorage.getItem(key) != null
        if (!alreadyShown) {
            setShowInlineDisclaimer(true)
            sessionStorage.setItem(key, 1)
        }*/
        setShowInlineDisclaimer(true)
    }, [])

    useEffect(() => {
        // if (!focus && loading != 2) {
        // setLoading(0)
        // }
    }, [focus])

    useEffect(() => {
        if (!focus) {
            return
        }
        let newUrl = `${getTourUrl()}/${media.link}`//media=1`
        if (newUrl[newUrl.length - 1] != '/') {
            newUrl += '/'
        }

        let showOptions = new Set()
        let hideOptions = new Set()
        let showTags = new Set()
        let hideTags = new Set()

        // Get selections specific to this group
        const optionMap = {}
        // fnc.objIdMap(group.options, 'upgradeOptionId', 'upgradeOptionId')
        group.options.forEach((x) => {
            optionMap[x.upgradeOptionId] = x.upgradeOptionId
            maps.groupOptions[group.id].forEach((x) => {
                optionMap[x] = x
            })
        })

        const newProductMap = {}
        const danglingPackageOptions = {}
        Object.keys(selections).forEach((x) => {
            selections[x].forEach((y) => {
                if (y.option && y.option.id in optionMap && (y.group?.id == group.id || y.option.global) && y.product?.id) {
                    let links = getTourLink(y, selections, app, maps, group)
                    links.forEach((link) => {
                        showOptions.add(link)
                    })
                    if (!(y.option.id in newProductMap)) {
                        newProductMap[y.option.id] = []
                    }
                    newProductMap[y.option.id].push(y)

                    // If is a package, check other packages for options that don't exist in this package. hide those options
                    if (y.package && y.package.id in maps.packageOption) {
                        const packageOption = maps.packageOption[y.package.id]
                        const packageOptionComponent = getOptionComponent(packageOption)
                        const key = `${y.package.id}-${group.id}`
                        if (!(key in danglingPackageOptions)) {
                            const otherPackages = packageOptionComponent.products.filter((z) => z.upgradePackageId != y.package.id).map((x) => {
                                return maps.package[x.upgradePackageId]
                            })
                            const thisPackageOptions = {}
                            const otherPackageOptions = {}
                            y.package?.products.forEach((z) => {
                                thisPackageOptions[z.upgradeOptionId] = z.upgradeOptionId
                            })
                            otherPackages.forEach((z) => {
                                z.products?.forEach((a) => {
                                    otherPackageOptions[a.upgradeOptionId] = a.upgradeOptionId
                                })
                            })
                            let danglers = new Set()
                            Object.values(otherPackageOptions).filter((z) => !(z in thisPackageOptions)).forEach((x) => {
                                const option = maps.option[x]
                                danglers.add(option.link)
                            })
                            if (danglers.size > 0) {
                                showTags.add(y.option.link)
                                danglingPackageOptions[key] = Array.from(danglers)
                            }

                        }
                    }
                } else if (y.product.upgradePackageId) {
                    showOptions.add(`${y.option.link}-${y.package.link}`)
                    if (!(y.option.id in newProductMap)) {
                        newProductMap[y.option.id] = []
                    }
                    newProductMap[y.option.id].push(y)
                }
            })
        })
        Object.keys(danglingPackageOptions).forEach((x) => {
            danglingPackageOptions[x].forEach((y) => {
                // Remove all show options with containing this tag
                hideTags.add(y)
                showOptions.forEach((x) => {
                    if (x.startsWith(y)) {
                        showOptions.delete(x)
                        hideOptions.add(x)
                    }
                })
            })
        })

        // Get list of selected and unselected variations
        const allVars = new Set()//editOption.variations.map((x) => x.id))
        const selectedVars = new Set()
        let multiMap = {}
        let unselectedVars = new Set()
        const varMap = {}//fnc.objIdMap(editOption.variations)
        group.options.forEach((x) => {
            const opt = maps.option[x.upgradeOptionId]
            if (opt?.variations.length > 1) {
                opt.variations.forEach((y) => {
                    allVars.add(y.id)
                    varMap[y.id] = { option: opt, variation: y }

                    // If product is not selected, add negative selection
                })
            }
            opt.variations.forEach((y) => {
                if (!y.multiSelect) {
                    return
                }
                y.components.forEach((z) => {
                    multiMap[z.id] = []
                    z.products.forEach((a) => {
                        multiMap[z.id][a.upgradeProductId] = { option: opt, variation: y, component: z, product: maps.product[a.upgradeProductId] }
                    })
                })
            })
        })
        Object.keys(selections).filter((x) => {
            return selections[x] && selections[x].find((y) => (y.group && y.group.id == group.id) || y.option.global)
        }).forEach((x) => {
            const sel = selections[x].find((y) => allVars.has(y.variation.id))
            if (sel != null) {
                selectedVars.add(sel.variation.id)
            }
            selections[x].forEach((y) => {
                if (y.variation.multiSelect && y.component.id in multiMap) {
                    if (y.product.id in multiMap[y.component.id]) {
                        delete multiMap[y.component.id][y.product.id]
                    }
                }

            })
        })
        Object.values(multiMap).forEach((x) => {
            Object.values(x).forEach((y) => {
                // Toggle off the multi map products
                let links = getTourLink(y, selections, app, maps, group)
                links.forEach((link) => {
                    link = link.replace('on', 'off')
                    showOptions.add(link)
                })
            })
        })

        unselectedVars = allVars.difference(selectedVars)

        // Show tags from selected vars
        Array.from(selectedVars).forEach((x) => {
            const { option, variation } = varMap[x]
            const key = `${option.link}-${variation.link}`
            showTags.add(key)
        })

        // Hide all options from unselected vars
        Array.from(unselectedVars).forEach((x) => {
            const { option, variation } = varMap[x]
            variation.components.forEach((y) => {
                y.products.forEach((z) => {
                    const key = `${y.link}-${z.upgradeProductId}`
                    hideOptions.add(key)
                })
            })
            // Hide variation tag
            const key = `${option.link}-${variation.link}`
            hideTags.add(key)

            // Remove options with this prefix
            showOptions.forEach((x) => {
                if (x.startsWith(key)) {
                    showOptions.delete(x)
                    hideOptions.add(x)
                }
            })
        })

        /*if (!editPackage && !editOption) {
            tagGroup = new Set(['top'])
            group.options.forEach((x) => {
                const opt = maps.option[x.upgradeOptionId]
                negGroup.add(getLink(opt?.link))
            })

            Array.from(selectedVars).forEach((x) => {
                if (true || varMap[x].option.collapse) {
                    const key = `top-${getLink(varMap[x].option.link)}-${varMap[x].variation.link}`
                    const keyB = `${getLink(varMap[x].option.link)}-${varMap[x].variation.link}`
                    if (varMap[x].option.collapse) {
                        tagGroup.add(key)
                        negGroup.add(keyB)
                    } else {
                        tagGroup.add(keyB)
                    }
                }
            })
            Array.from(allVars.difference(selectedVars)).forEach((x) => {
                const key = `top-${getLink(varMap[x].option.link)}-${varMap[x].variation.link}`
                const keyB = `${getLink(varMap[x].option.link)}-${varMap[x].variation.link}`
                negGroup.add(key)
                negGroup.add(keyB)
            })

        } else if (editPackage && !editOption) {
            const packageOption = maps.packageOption[editPackage.id]
            tagGroup.add(getLink(packageOption.link))
            // For negative use all top level group options
            group.options.filter((x) => x.upgradeOptionId != packageOption.id).forEach((x) => {
                const opt = maps.option[x.upgradeOptionId]
                negGroup.add(getLink(opt?.link))
            })
            negGroup.add('top')
            addVariationNeg()
        } else if (editOption) {
            // If editing option has numerous variations, hide others and show variation points
            if (editOption.variations.length > 1) {
                group.options.forEach((x) => {
                    const opt = maps.option[x.upgradeOptionId]
                    negGroup.add(getLink(opt?.link))
                })
                negGroup.add('top')

                Array.from(allVars).forEach((x) => {
                    // Remove top level vars
                    negGroup.add(`top-${getLink(editOption.link)}-${varMap[x].variation.link}`)
                })

                Array.from(selectedVars).forEach((x) => {
                    tagGroup.add(`${getLink(editOption.link)}-${varMap[x].variation.link}`)
                })
                Array.from(allVars.difference(selectedVars)).forEach((x) => {
                    negGroup.add(`${getLink(editOption.link)}-${varMap[x].variation.link}`)
                })
            }
        }*/


        let params = []
        hideSet.current = hideSet.current.union(showOptions)

        logger.info("Tour selections", selections)

        logger.info("Tour for group ", group.name)

        if (editOption && editOption.rotation && focusOption) {
            params.push(`pitch=${editOption.rotation[0]}&yaw=${editOption.rotation[1]}`)
        }
        if (showTags.size > 0) {
            logger.info('Tour show tags', showTags)
            params.push(`sot=${Array.from(showTags).join(',')}`)
        }
        if (hideTags.size > 0) {
            logger.info('Tour hide tags', hideTags)
            params.push(`hot=${Array.from(hideTags).join(',')}`)
        }

        if (showOptions.size > 0) {
            // Must include trailing slash or will re-load on each change
            const finalShow = Array.from(showOptions)
            logger.info('Tour show options', finalShow)
            params.push(`son=${finalShow.join(',')}`)
        }

        if (hideOptions.size > 0) {
            const finalHide = Array.from(hideSet.current.difference(showOptions).union(hideOptions))
            logger.info('Tour hide options', finalHide)
            params.push(`hon=${finalHide.join(',')}`)
        }
        if (screen.isMobile) {
            newUrl += '?media-index=2'
        }
        newUrl += `#${params.join('&')}`
        logger.info('Tour Url: ' + newUrl)
        setProductMap(newProductMap)
        setUrl(newUrl)
    }, [selections, editOption, focusOption, editPackage, focus])

    useEffect(() => {
        if (!focus) {
            return
        }
        window.addEventListener('message', handleWindowEvent)
        return () => {
            window.removeEventListener('message', handleWindowEvent)
        }
    }, [editOption, productMap, focus])

    function handleFullscreen() {
        // if (screen.fullscreenSupport) {
        // fnc.toggleFullscreen(viewRef)
        // }
        dispatch(toggleFullscreen())
        if (viewRef.current) {
            viewRef.current.classList.toggle('fullscreen')
        }
    }

    function handleWindowEvent(e) {
        // window.dispatchEvent(new CustomEvent(CustomEventType.DebugMessage, { detail: JSON.stringify(e.data)}))

        let option = null
        let variationLink = null
        let componentLink = null
        let variation = null
        let component = null
        let pckgeOption = null
        let pckge = null
        let packages = null
        if (e.data.option) {
            // See if link includes variation / component
            const tokens = e.data.option.split('-')
            const optionLink = tokens[0]
            if (tokens.length > 1) {
                variationLink = tokens[1]
                if (tokens.length > 2) {
                    componentLink = tokens[2]
                }
            }
            option = Object.values(maps.groupOptions[group.id]).map((x) => {
                const opt = maps.option[x]
                return opt
            }).find((x) => {
                return x.link == optionLink || x?.link.endsWith(`_${optionLink}`)
            })

            if (option?.id in maps.optionPackage) {
                // if (e.data.package) {
                pckgeOption = Object.values(maps.groupOptions[group.id])
                    .map((x) => maps.option[x])
                    .find((x) => {
                        // return x.link == e.data.package || x?.link.endsWith(`_${e.data.package}`)
                        return x.link == optionLink || x?.link.endsWith(`_${optionLink}`)
                    })
                if (e.data.option && e.data.package && option?.id in maps.optionInPackage) {
                    packages = Object.keys(maps.optionInPackage[option?.id])?.map((x) => maps.package[x])
                }
            }
        }

        if (!pckgeOption && e.data.package) {
            pckgeOption = Object.values(maps.groupOptions[group.id])
                .map((x) => maps.option[x])
                .find((x) => {
                    return x.link == e.data.package || x?.link.endsWith(`_${e.data.package}`)
                })

        }
        if (e.data == 'tourLoaded') {
            setLoading(1)
            setTimeout(() => {
                setLoading(2)
                setShowHandAnimation(true)
                setTimeout(() => {
                    setShowHandAnimation(false)
                }, 5000)
            }, 200)
        } else if (e.data && e.data.type == 'focus') {
            logger.info("Focus event", e, e.data.option, e.data.package)
            if (option) {
                if (variationLink) {
                    variation = option.variations.find((x) => x.link == variationLink)
                    if (componentLink) {
                        component = variation.components.find((x) => x.link == componentLink)
                    }
                }
                // Conditions to avoid drill down
                let avoidDrill = false
                if (packages && packages.length > 0) {
                    // Check all packages associated with options
                    avoidDrill = packages.some((x) => !x.configurable)
                    // Change option
                    if (avoidDrill) {
                        option = pckgeOption = maps.packageOption[packages[0]?.id]
                    }
                }

                // if (!editPackage && !pckge && !option.collapse) {// || editPackageOption?.id == pckge?.id) && !option.collapse) {
                if ((avoidDrill || !pckgeOption || (pckgeOption == option && !(e.data.package && e.data.option))) && !option.collapse) {// || editPackageOption?.id == pckge?.id) && !option.collapse) {
                    // Select package first
                    /*if (pckge && onSelect) {
                        onSelect(pckge, null, true)
                    }*/
                    setTimeout(() => {
                        window.dispatchEvent(new CustomEvent(CustomEventType.FocusUpgradeOption, { detail: { group, option, variation, component, pckge: pckgeOption } }))
                    }, 100)
                } else {
                    if (onSelect) {
                        if (pckgeOption && pckgeOption != option) {
                            onSelect(pckgeOption, null, true, true)
                        }
                        if (!variation && component) {
                            variation = option.variations[0]
                            if (variation.components.length == 1) {
                                component = variation.components[0]
                            }
                        }
                        onSelect(option, component, true)
                    }
                }
            }
        } else if (e.data && e.data.type == 'edit') {//} || e.data.type == 'toggle')) {
            logger.info("Edit event", e, option)
            if (option && onSelect) {
                const variation = e.data.variation ? option.variations.find((x) => x.link == e.data.variation) : option.variations[0]
                const component = variation ? variation.components[0] : null
                onSelect(option, component, true)
            }
        } else if (e.data && e.data.type == 'toggle') {
            logger.info("Toggle event", e)
            const sortedOptions = [...group.options].sort(orderSort).map((x) => maps.option[x.upgradeOptionId])
            const optionLink = e.data.option
            const option = sortedOptions.find((x) => x.link.endsWith(optionLink))
            if (onProduct && option) {
                if (option.collapse) {
                    // Focus
                    window.dispatchEvent(new CustomEvent(CustomEventType.FocusUpgradeOption, { detail: { option } }))
                } else {
                    const variation = e.data.variation ? option.variations.find((x) => x.link == e.data.variation) : option.variations[0]
                    const component = variation ? variation.components[0] : null
                    if (!component) {
                        return null
                    }
                    const products = [...component.products].sort(orderSort).map((x) => maps.product[x.upgradeProductId]).filter((x) => x)
                    const packages = [...component.products].sort(orderSort).map((x) => maps.package[x.upgradePackageId]).filter((x) => x)
                    if (products.length > 0) {
                        const productsSelected = option.id in productMap ? productMap[option.id] : []
                        let newIdx = 0
                        if (productsSelected.length > 0) {
                            newIdx = products.findIndex((y) => y.id == productsSelected[0].product.id)
                        }
                        if (newIdx == -1) {
                            newIdx = 0
                        } else {
                            newIdx += 1
                            if (newIdx >= products.length) {
                                newIdx = 0
                            }
                        }
                        const newProduct = products[newIdx]

                        onProduct(group, option, variation, component, newProduct)
                    } else if (packages.length > 0) {
                        let newIdx = 0
                        const packagesSelected = option.id in productMap ? productMap[option.id] : []
                        if (packagesSelected.length > 0) {
                            newIdx = packages.findIndex((y) => y.id == packagesSelected[0].package.id)
                        }
                        if (newIdx == -1) {
                            newIdx = 0
                        } else {
                            newIdx += 1
                            if (newIdx >= packages.length) {
                                newIdx = 0
                            }
                        }
                        const newPackage = packages[newIdx]
                        onPackage(group, option, variation, component, newPackage)
                    }
                }
            }
        }

        // window.dispatchEvent(new CustomEvent({ default}))
    }

    let prompt = null
    if (showInlineDisclaimer) {
        prompt = { ...PromptOptions.UpgradeTourDisclaimer }
        if (group.disclaimer && group.disclaimer.length > 0) {
            prompt.message = group.disclaimer
        }
    }


    return <React.Fragment>
        {loading < 2 && <Spinner overlay invert show={loading == 0} showAfter={0} quotesAfter={0} />}
        {/* {<Spinner overlay invert show={true} showAfter={0} quotesAfter={0} />} */}
        {!url && <Spinner invert />}
        {screen.fullscreenSupport && <div className="top-right">
            <IconButton icon={isFullscreen ? "fas fa-compress-alt" : "fas fa-expand-alt"} onClick={handleFullscreen} />
        </div>}
        {/* {url && focus && <TourIFrame src={url} />} */}
        {url && (focus || loading > 0) && <TourIFrame src={url} />}
        {loading == 2 && showHandAnimation && <div className="hand-animation">
            <Icon icon="fas fa-hand-point-up" className='animate__animated animate__fadeIn' noBg />
        </div>}
        {showInlineDisclaimer && <Prompt prompt={prompt} onDismiss={() => setShowInlineDisclaimer(false)} />}
    </React.Fragment>
}

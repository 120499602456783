import React, { useState, useEffect } from 'react'
import { ChromePicker } from 'react-color'
import { CustomEventType } from 'app/types'
import { Button } from 'components'

interface ColorPickerProps {
    color: string
    onChange: (color: string) => void,
}
export function ColorPicker(props) {
    const { color, onChange } = props
    const [picking, setPicking] = useState(false)

    useEffect(() => {
        window.addEventListener(CustomEventType.CloseAllMenus, handleClose)
        window.addEventListener('click', handleClose)
        return () => {
            window.removeEventListener(CustomEventType.CloseAllMenus, handleClose)
            window.removeEventListener('click', handleClose)
        }
    })

    function handleClose() {
        setPicking(false)
    }

    function handleClick(e) {
        window.dispatchEvent(new CustomEvent(CustomEventType.CloseAllMenus))
        e.stopPropagation()
        setPicking(true)
    }

    function handleClear(e) {
        e.stopPropagation()
        setPicking(false)
        onChange(null)
    }

    function handleChange(x) {
        if (onChange) {
            const newColor = x.hex.replace('#', '')
            if (newColor != color) {
                onChange(newColor)
            }
        }
    }

    const style = {}// backgroundColor: `#${color}` }
    const hexStyle = { backgroundColor: `#${color}`, color: 'white' }
    if (!color || color == 'ffffff') {
        // hexStyle.color = 'black'
        hexStyle.backgroundColor = 'white'
        hexStyle.color = 'black'
    }

    return <div className="color-picker" style={style} onClick={handleClick}>
        <span className="hex" style={hexStyle}>{color && color.length > 0 ? `#${color}` : 'Not set'}</span>
        {color && color.length > 0 && <Button onClick={handleClear} icon="fas fa-times">Clear</Button>}
        {picking && <ChromePicker color={color ? `#${color}` : '#ffffff'} onChange={handleChange} />}
    </div>
}
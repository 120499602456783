import React, { useState, useEffect, Suspense, useRef } from 'react'
import { useAppSelector, useAppDispatch, usePage } from 'app/hooks'
import { RootState, ErrorMessage, PageType } from 'app/types'
import {
    Media,
    OptionBar,
    BreadCrumbs,
    ScrollHelper,
    Spinner,
} from 'components'
import { organizationContent, projectContent } from 'app/transformers'
import { Footer } from 'views/HomePage/Footer'
import { getWindowLocation } from 'helpers/config'
import { navigateAsync } from 'actions/appActions'

interface CustomPageProps {
    app: AppData,
    splitIndex: number,
    breadCrumbs: boolean,
    pageLink: PageType,
}

export function DynamicPage(props: CustomPageProps) {
    const { app, splitIndex, breadCrumbs, pageLink } = props
    const page = usePage()
    // const page = app ? app.pages.find((x) => x.link === pageLink) : null
    const [scrollElem, setScrollElem] = useState()
    const standaloneOrg = useAppSelector((state: RootState) => state.app.standaloneOrg)
    const organization = useAppSelector((state: RootState) => state.app.organization)
    const showOptionbar = organization && organization.link == 'homegyde'
    const dispatch = useAppDispatch()
    const [finalLink, setFinalLink] = useState(pageLink)
    const [loading, setLoading] = useState(1)
    const [scrollInit, setScrollInit] = useState(null)

    useEffect(() => {
        setFinalLink(pageLink)
    }, [pageLink])

    useEffect(() => {
        setLoading(1)
        if (scrollInit == null) {
            return
        }
        // Give content a moment to layout and scroll to cached location
        setTimeout(() => {
            // Check for page anchor
            if (scrollElem && scrollInit != 0) {
                scrollElem.scrollTop = scrollInit
                setTimeout(() => {
                    setLoading(0)
                }, 100)
            } else {
                setLoading(0)
            }
        }, 100)
    }, [pageLink, scrollInit])

    // if (!page || !app) {
    // return <h3 className="error-message">{ErrorMessage.PageNotFound} </h3>
    // }

    useEffect(() => {
        if (!scrollElem) {
            return
        }

        function checkHash() {
            if (getWindowLocation().hash.length > 1) {
                const id = getWindowLocation().hash.slice(1, getWindowLocation().hash.length)

                switch (id) {
                    case 'register': {
                        // Check for scroll elem as page loads
                        const tryScroll = (tries = 5) => {
                            if (tries == 0) {
                                return
                            }
                            const targetElem = document.getElementById(id)
                            if (targetElem) {
                                targetElem.scrollIntoView({ behavior: 'smooth' })
                            } else {
                                setTimeout(() => {
                                    tryScroll(tries - 1)
                                }, 100)
                            }
                        }
                        setTimeout(() => {
                            tryScroll()
                        }, 1000)
                        break
                    }
                    case 'thankyou':
                        setFinalLink(id)
                        break
                }
            }
        }
        window.addEventListener('hashchange', checkHash)
        checkHash()
        return () => {
            window.removeEventListener('hashchange', checkHash)

        }
    }, [scrollElem])

    function handleScrollInitialize(x) {
        setScrollInit(x)
    }

    function handleRedirect(x) {
        if (scrollElem) {
            // Scroll to top
            setTimeout(() => {
                scrollElem.scrollTo({ top: 0, behavior: 'smooth' })
            }, 100)
            // scrollElem.scrollTop = 0
        }
        if (x.includes('#')) {
            const tokens = x.split('#')
            const hash = tokens[tokens.length - 1]
            window.location.hash = hash
        } else {
            dispatch(navigateAsync({ app, pageType: x }))
        }
    }


    function getCustomView() {
        let ContentComponent = null
        if (app) {
            ContentComponent = projectContent(app)
        } else if (organization) {
            ContentComponent = organizationContent(organization)
        }
        if (ContentComponent) {
            return <Suspense fallback={<Spinner overlay />}>
                <ContentComponent app={app} organization={organization} link={finalLink} onRedirect={handleRedirect} scrollElem={scrollElem} />
            </Suspense>
        }
        return <h3> Custom </h3>
        /*switch (app.meta.link) {
        default:
            return <h3> Custom </h3>
            break
        case 'test':
        case 'thewoods':
            return <Suspense fallback={<Spinner overlay/>}><TheWoodsContent app={app} link={page.link}/></Suspense>
        case 'kingsleysquare':
            // TODO: break out into other files?
            switch (page.link) {
            default:
                return null
            case 'gettingaround':
                return <div className="getting-around">
                    <Media app={app} mediaId="kingsleysquare_gettingaroundA" className="left" spinner fadeIn fadeInDelay={50}/>
                    <Media app={app} mediaId="kingsleysquare_gettingaroundB" className="right" spinner fadeIn fadeInDelay={50}/>
                </div>
                break

            case 'thingstodo':
                return <div className="things-to-do">

                    <Media app={app} mediaId="kingsleysquare_thingstodo_1" className="full-width" spinner fadeIn fadeInDelay={50}/>
                    <Media app={app} mediaId="kingsleysquare_thingstodo_2" spinner fadeIn fadeInDelay={50}/>
                    <Media app={app} mediaId="kingsleysquare_thingstodo_3" spinner fadeIn fadeInDelay={50}/>
                    <Media app={app} mediaId="kingsleysquare_thingstodo_4" spinner fadeIn fadeInDelay={50}/>
                    <Media app={app} mediaId="kingsleysquare_thingstodo_5" spinner fadeIn fadeInDelay={50}/>
                </div>

            case 'amenities':
                return <GalleryView app={app} gallery={app.galleries.find((x) => x.link == 'amenities')} showFilters={false}/>
            }
        }*/
    }
    let key = finalLink
    let style = {}
    if(page?.backgroundColor) {
        style.backgroundColor = `#${page.backgroundColor}`
        key = `${key}${page.backgroundColor}`
    }

    return <React.Fragment>
        {showOptionbar && <OptionBar app={app} splitIndex={splitIndex} titleText={app?.meta.name} />}
        <div className={`content dynamic scrollable ${loading ? ' loading' : ''}`} ref={setScrollElem} key={key} style={style}>
            {/* <div className="topbar sticky custom only-desktop"></div> */}
            {breadCrumbs && <BreadCrumbs app={app} splitIndex={splitIndex} />}
            {getCustomView()}
            <Footer />
        </div>
        <ScrollHelper scrollElem={scrollElem} pageLink={finalLink} onScrollInitialize={handleScrollInitialize} />
        {/* {!app && <ScrollHelper scrollElem={scrollElem} />} */}
    </React.Fragment>
}